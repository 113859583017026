import React from "react";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyle from "./GlobalStyle.js";
import Layout from "./components/Layout.js";
import Home from "./pages/Home.js";
import Create from "./pages/Create.js";
import Token from "./pages/Token.js";
import MPC from "./pages/app/Mpc.js";
import Portfolio from "./pages/Portfolio.js";
import EventListener from "./components/EventListener.js";
import { EventProvider } from "./context/EventContext.js";

function App() {
  return (
    <Router>
      <EventProvider>
      <GlobalStyle />
      <Routes>
        <Route
          path="/"
          element={
            <Layout title="Home - PumpEvm">
              <EventListener />
              <Home />
            </Layout>
          }
        />
        <Route
          path="/create"
          element={
            <Layout title="Create New Token on PumpEvm">
              <Create />
            </Layout>
          }
        />
         <Route
          path="/portfolio"
          element={
            <Layout title="User Tokens">
              <Portfolio />
            </Layout>
          }
        />
     
        <Route
          path="/trade/:token"
          element={
              <Token />
          }
        />
        <Route path="/app/wallet" element={<MPC />} />
      </Routes>
      </EventProvider>
    </Router>
  );
}

export default App;

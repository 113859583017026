import React, { useEffect, useState } from "react";
import { toShortAddress, formatNumber } from "../utils/formatter";
import { useEventContext } from "../context/EventContext";

const EventListener = () => {
  const { data } = useEventContext();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [shuffledData, setShuffledData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      // Shuffle data except the first item
      const [firstItem, ...restItems] = data;
      const shuffledItems = restItems.sort(() => 0.5 - Math.random());
      setShuffledData([firstItem, ...shuffledItems]);
    }
  }, [data]);

  useEffect(() => {
    if (shuffledData.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % shuffledData.length);
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [shuffledData]);

  if (!shuffledData || shuffledData.length === 0) return null;

  const currentItem = shuffledData[currentIndex];
  const amount = parseFloat(currentItem.amount) || 0; // Ensure amount is a number

  return (
    <div className="events__logger">
      <div>
        {currentItem.type === "buy" && (
          <p className="buy__events">
            {toShortAddress(currentItem.wallet)} bought {amount ? formatNumber(amount) : 0} of {currentItem.name}
          </p>
        )}
        {currentItem.type === "sell" && (
          <p className="sell__events">
            {toShortAddress(currentItem.wallet)} jeets {amount.toFixed(4)} PEPE from {currentItem.name}
          </p>
        )}
      </div>
    </div>
  );
};

export default EventListener;

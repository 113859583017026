export const toShortAddress = (addr) => {
    if (!addr) return null;
    const address =
      addr.substring(0, 5) + "..." + addr.substring(37, addr.length);
    return address;
  };
  
  export const toLongAddress = (addr) => {
    if (!addr) return null;
    const address =
      addr.substring(0, 7) + "..." + addr.substring(35, addr.length);
    return address;
  };
  
  export const toInt = (value) => {
    return parseInt(value, 10);
  };
  
  export function formatNumber(value) {
    // Check if value is a number
    if (isNaN(value)) {
      return value; // Return the original value if it's not a number
    }
  
    const thresholds = {
      billion: 1e9, // 1 billion
      million: 1e6, // 1 million
      thousand: 1e3, // 1 thousand
    };
  
    const formatter = new Intl.NumberFormat("en-US"); // Format numbers with commas
  
    if (value >= thresholds.billion) {
      return (value / thresholds.billion).toFixed(2) + "B";
    } else if (value >= thresholds.million) {
      return (value / thresholds.million).toFixed(2) + "M";
    } else if (value >= thresholds.thousand) {
      return formatter.format(Math.round(value)) + ""; // Adds commas for thousands
    } else {
      return formatter.format(value) + ""; // Direct formatting for values less than a thousand
    }
  }
  
  export function secondsToDays(seconds) {
    const secondsInDay = 86400; // 24 hours * 60 minutes * 60 seconds
    const days = seconds / secondsInDay;
  
    return days;
  }
export  function formatMarketCap(value) {
    if (value >= 1_000_000_000) {
        return (value / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
    } else if (value >= 1_000_000) {
        return (value / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (value >= 1_000) {
        return (value / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
    } else {
        return value.toString();
    }
}

import React from "react";
import "../../assets/css/mpc.css"
import { Container } from "semantic-ui-react";
import scan from "../../assets/images/scan.png"
import blk from "../../assets/images/blocka.webp"
import jlk from "../../assets/images/blockB.webp"

function Mpc() {
  return (
    <React.Fragment>
            
      <div className="wallet">
        <Container>

        <section className="py-5">
            <div className="header__one">
               <div>
               <img src={blk} alt=""/>
               <span>0x324...8930 <i class="fa-solid fa-copy"></i></span>
               </div>
               <img className="scan" src={scan} alt=""/>

            </div>
        </section>
        <section className="py-5">
            <div className="header__two">
                <h1>$ 22,223.75</h1>
                <span><i class="fa-solid fa-arrow-trend-up"></i>$145.01 (10%)</span>
            </div>
        </section>

        <section className="py-5">
            <div className="header__three">
                <span className="buy btn"><i class="fa-solid fa-credit-card"></i>Buy</span>
                <span className="send btn"><i class="fa-solid fa-arrow-up-right-dots"></i>Send</span>
                <span className="rec btn"><i class="fa-solid fa-arrow-down"></i>Receive</span>
            </div>
        </section>
        </Container>


        <section className="dark__bg">
            <div className="mt-3">

                <div className="mini__nav">

                    <span className="btn">Tokens</span>
                    <span className="btn">Activities</span>

                </div>


                <div className="mt-5 tnx">

                    <div className="tnx__item">
                        <div className="it_one">
                            <img src={jlk} alt="."/>
                            <div className="inner"><b>USDT</b> <span>USD Coin</span> </div>
                        </div>

                        <div className="inner layer">
                            <b>3,345</b>
                            <span>$3,344</span>
                        </div>

                    </div>

                    <div className="tnx__item">
                        <div className="it_one">
                            <img src={jlk} alt="."/>
                            <div className="inner"><b>BTC</b> <span>Btc Coin</span> </div>
                        </div>

                        <div className="inner layer">
                            <b>5</b>
                            <span>$161,344</span>
                        </div>

                    </div>
                    <div className="tnx__item">
                        <div className="it_one">
                            <img src={jlk} alt="."/>
                            <div className="inner"><b>ETH</b> <span>Eth Coin</span> </div>
                        </div>

                        <div className="inner layer">
                            <b>10.9</b>
                            <span>$250,000</span>
                        </div>

                    </div>

                    <div className="tnx__item">
                        <div className="it_one">
                            <img src={jlk} alt="."/>
                            <div className="inner"><b>BNB</b> <span>Bnb Coin</span> </div>
                        </div>

                        <div className="inner layer">
                            <b>7.98</b>
                            <span>$2,660</span>
                        </div>

                    </div>

                </div>

            </div>
        </section>

      </div>
    </React.Fragment>
  );
}

export default Mpc;

import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract } from "ethers";
import { bondingCurverFactory } from "../config/contracts.js";
import { bondingCurverFactoryABI } from "../config/abi.js";
import { useNavigate } from "react-router-dom";
import { notify } from "../utils/notification";
import { PinataSDK } from "pinata-web3";


const pinata = new PinataSDK({
  pinataJwt:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI3OTRkY2ZmZi1jYmUzLTQzOWQtYTgzMi04YzMyYmMyN2E3NTYiLCJlbWFpbCI6InB1bXBldm05OEBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MSwiaWQiOiJGUkExIn0seyJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MSwiaWQiOiJOWUMxIn1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiYzMyNTZmNWQ1ZjhlNWFiMDk0NGQiLCJzY29wZWRLZXlTZWNyZXQiOiI3OWFiNzdiODFjMDQ5YjBhZTFkNjQwNDNmN2Y1MTQzMTE4YTY1NTg4ZGVhZWVhNDg0OGEzYmZmMDgwZGY1MmExIiwiZXhwIjoxNzY0MDgzMDIyfQ.dbFLLJoEYLuEYo3HgcY25KAgQqhy8WDs2GGwKNCKPDU",
  pinataGateway: "example-gateway.mypinata.cloud",
});


function Create() {
  const navigate = useNavigate();
  const { isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [loading, setLoading] = useState(false);
  const [isSocials, setSocials] = React.useState(false);
  const [imageURL, setImageURL] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    ticker: "",
    description: "",
    twitter: "",
    website: "",
    telegram: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      setErrorMessage("Please upload a valid image file.");
      return;
    }

    // Check if the file size is less than 1MB
    if (file.size > 1000000) {
      setErrorMessage("File size exceeds 1MB. Please upload a smaller image.");
      return;
    }

    setErrorMessage(""); 

    try {
      const upload = await pinata.upload.file(file);
      const hash = upload.IpfsHash;
      setImageURL(`https://blue-brilliant-hoverfly-466.mypinata.cloud/ipfs/${hash}`);
      console.log(upload);
    } catch (error) {
      console.error("Error uploading to IPFS:", error);
      setErrorMessage("Failed to upload image to IPFS. Please reselect the image.");
    }
  };




  async function createToken() {
    setLoading(true);
    if (!isConnected) {
      notify("Please connect your wallet");
      return;
    }
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const factory = new Contract(
        bondingCurverFactory,
        bondingCurverFactoryABI,
        signer
      );
      const FEE = await factory.tokenCreationFee();

      const { name, ticker, description, twitter, website, telegram } =
        formData;

      const ct = twitter ? twitter : "https://twitter.com/";
      const web = website ? website : "/";
      const tg = telegram ? telegram : "https://t.me/";
      const urls = [imageURL, ct, tg, web];
      const tx = await factory.createToken(name, ticker, description, urls, {
        value: FEE,
        gasLimit: 5000000,
      });
      await tx.wait();

      const deployedContractsAfter = await factory.getAllTokens();

      const lastDeployedContract =
        deployedContractsAfter[deployedContractsAfter.length - 1];

      notify("Token created successfully", "success");

      setTimeout(() => {
        navigate(`/trade/${lastDeployedContract}`);
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
      let errorMessage = "An unknown error occurred.";

      // Check if the error has the expected structure
      if (error && error.data && error.data.message) {
        errorMessage = error.data.message; // Extract the specific error message
      } else if (error && error.message) {
        // Fallback to checking for the error message directly
        errorMessage = error.message;
      }

      // Display the extracted error message to the user
      console.error(errorMessage);
      errorMessage =
        errorMessage.length > 100
          ? `${errorMessage.slice(0, 150)}...`
          : errorMessage;
      notify(errorMessage);
    }
  }
  return (
    <React.Fragment>
      <div className="mt-5">
        <Link to="/">[Back]</Link>
      </div>

      <section className="py-5 create__token">
        <h3 className="text-center">Create a Meme</h3>
        <div>
          <Form onSubmit={createToken}>
            <Form.Field>
              <p>Name</p>
              <input
                placeholder="Token Name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Field>

            <Form.Field>
              <p>Ticker</p>
              <input
                placeholder="Token Ticker"
                type="text"
                name="ticker"
                value={formData.ticker}
                onChange={handleChange}
                required
              />
            </Form.Field>

            <Form.Field>
              <p>Description</p>
              <input
                placeholder="Token Description"
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Form.Field>

            <Form.Field>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              <p>Image (MAX 1MB)</p>
              <span style={{ color: "red", fontSize: "10px"}}>Make sure your uploaded image shows on this page before confirming transaction.</span>
              <input
                 type="file"
                 accept="image/*"
                 onChange={handleImageUpload}
                 disabled={loading}
                required
              />
            </Form.Field>

            {imageURL ? <img src={imageURL} alt="Uploaded" style={{ maxWidth: "100px" }} /> : ""}

            <h4 onClick={() => setSocials(true)} style={{ cursor: "pointer" }}>
              [ Add Socials ]
            </h4>

            {isSocials ? (
              <div className="socials">
                <Form.Field>
                  <p>Twitter link (optional)</p>
                  <input
                    placeholder="Twitter URL"
                    type="url"
                    name="twitter"
                    value={formData.twitter}
                    onChange={handleChange}
                  />
                </Form.Field>

                <Form.Field>
                  <p>Website link (optional)</p>
                  <input
                    placeholder="Website URL"
                    type="url"
                    name="website"
                    value={formData.website}
                    onChange={handleChange}
                  />
                </Form.Field>

                <Form.Field>
                  <p>Telegram link (optional)</p>
                  <input
                    placeholder="Telegram URL"
                    type="url"
                    name="telegram"
                    value={formData.telegram}
                    onChange={handleChange}
                  />
                </Form.Field>
              </div>
            ) : null}

            <div className="mt-5">
              {isConnected ? (
                <Button
                  loading={loading}
                  style={{
                    background: "rgba(81, 252, 139, 0.79)",
                    color: "#fff",
                  }}
                  type="submit"
                  fluid
                >
                  Create
                </Button>
              ) : (
                <Button
                  style={{
                    background: "rgba(81, 252, 139, 0.79)",
                    color: "#fff",
                  }}
                  disabled
                  fluid
                >
                  Please Connect Wallet
                </Button>
              )}
            </div>
          </Form>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Create;

import React, { useEffect, useState } from "react";
import { Button, Input, Icon, Modal, Form, Comment } from "semantic-ui-react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, parseEther, formatUnits } from "ethers";
import { PACKETFACTORY } from "../config/contracts";
import { PACKETFACTORYABI, PACKETABI } from "../config/abi";
import { notify } from "../utils/notification";
import axios from "../api/axios";
import { toShortAddress } from "../utils/formatter";
import { approveToken } from "../utils/blockFunction";
import block from "../assets/images/blocka.webp";

function Chat({ token, data, updateFn }) {
  const { isConnected } = useWeb3ModalAccount();
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [maxClaimants, setMaxClaimants] = useState("");
  const [loading, setLoading] = useState(false);
  const [packets, setPackets] = useState([]);

  useEffect(() => {
    getAllPackets();
  }, [token]);

  async function getAllPackets() {
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const packetFactory = new Contract(
        PACKETFACTORY,
        PACKETFACTORYABI,
        signer
      );
      const packets = await packetFactory.getPacketsByToken(token);

      const packetDetails = await Promise.all(
        packets.map(async (packetAddress) => {
          const packetContract = new Contract(packetAddress, PACKETABI, signer);

          const remainingClaims = await packetContract.remainingClaims();

          return {
            address: packetAddress,
            remainingClaims: remainingClaims.toString(), 
          };
        })
      );
      setPackets(packetDetails);
    } catch (e) {
      console.log(e);
    }
  }

  const handleClaim = async (packet) => {
    if (!isConnected) {
      notify("Connect wallet and try again");
      return;
    }
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const packetContract = new Contract(packet, PACKETABI, signer);
    const left = await packetContract.remainingClaims();
    if (left <= 0) {
      notify("No more claims left");
      return;
    }
    try {
      const tx = await packetContract.claimPackets();
      await tx.wait();
      notify("Claimed successfully");
      await updateFn();
    } catch (error) {
      let errorMessage = "An unknown error occurred.";

      if (error && error.data && error.data.message) {
        errorMessage = error.data.message; // Extract the specific error message
      } else if (error && error.message) {
        // Fallback to checking for the error message directly
        errorMessage = error.message;
      }

      // Display the extracted error message to the user
      console.error(errorMessage);
      errorMessage =
      errorMessage.length > 100
        ? `${errorMessage.slice(0, 150)}...`
        : errorMessage;
      notify(errorMessage);
    }
  };

  const handlePackets = async () => {
    if (!amount || !maxClaimants) {
      notify("Please fill in the required fields");
      return;
    }
    if (amount <= 0 || maxClaimants <= 0) {
      notify("Amount and Max Claimants must be greater than 0");
      return;
    }
    if (amount > parseFloat(formatUnits(data.balance))) {
      notify("Insufficient balance");
      return;
    }
    setLoading(true);
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();

    const packetFactory = new Contract(PACKETFACTORY, PACKETFACTORYABI, signer);

    try {
      const wei = parseEther(amount);
      const tnx = await packetFactory.createPackets(
        token,
        wei,
        maxClaimants,
        address
      );
      notify("Please do not close the window");

      await tnx.wait();

      const allPackets = await packetFactory.getPackets();

      const newPacketContract = allPackets[allPackets.length - 1];
      await approveToken(amount, token, newPacketContract, signer, address);

      const packetContract = new Contract(newPacketContract, PACKETABI, signer);
      const tx = await packetContract.createPackets(wei);
      await tx.wait();

      await getAllPackets();

      notify("Packet created successfully");
      setLoading(false);
      await updateFn();
    } catch (error) {
      console.log(error);

      setLoading(false);
      let errorMessage = "An unknown error occurred.";

      if (error && error.data && error.data.message) {
        errorMessage = error.data.message; 
      } else if (error && error.message) {
        errorMessage = error.message;
      }

      console.error(errorMessage);
      errorMessage =
      errorMessage.length > 100
        ? `${errorMessage.slice(0, 150)}...`
        : errorMessage;
      notify(errorMessage);
    }
  };

  const [message, setMessage] = useState("");
  const [comments, setComments] = useState([]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetchComments();
  }, [token]);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  async function fetchComments() {
    try {
      const res = await axios.get(`/getcomment/${token}`);
      setComments(res.data.data);
    } catch (e) {
      console.log(e);
    }
  }
  const handleSend = async () => {
    const img_url =
      "https://res.cloudinary.com/dkpo6c3me/image/upload/v1729951155/blocka_tpw2sr.webp";
    if (!isConnected) {
      notify("Connect wallet and try again");
      return;
    }
    if (!message) return;
    try {
      const data = {
        contractAddress: token,
        wallet: address,
        message: message,
        img_url: img_url,
      };
      setMessage("");
      const res = await axios.post("/comment", data);
      await fetchComments();
      setMessage("");
      setFile(null);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
    className="mt-2"
      style={{
        padding: "20px",
        width: "100%",
        borderRadius: "10px",
        border: "1px solid #ccc",
        color: "#fff",
      }}
    >
      {packets.length > 0 ? (
        <div>
          <h3 style={{ color: "#fff" }}>Packets</h3>
          {packets.map((packet, index) => {
            return (
              <div
                key={index}
                className="d-flex mt-2"
                style={{ justifyContent: "space-between" }}
              >
                {packet.remainingClaims > 0 ? (
                  <>
                    <p>PacketID: {toShortAddress(packet.address)}</p>
                    <p>{packet.remainingClaims} lefts</p>
                    <Button
                      style={{
                        background: "rgba(81, 252, 139, 0.79)",
                        color: "#fff",
                        fontSize: "10px",
                        padding: "5px 5px", // Smaller padding for a more compact button
                        height: "20px", // Adjust height to make the button smaller
                        borderRadius: "5px", // Adjust border radius for rounded corners
                        minWidth: "50px", // Ensure a consistent button width
                      }}
                      onClick={() => handleClaim(packet.address)}
                    >
                      Claim
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      ) : null}

      <Comment.Group>
        {comments.length > 0 ? (
          <>
            {comments.map((comment, index) => (
              <Comment key={index}>
                <Comment.Avatar src={block} />
                <Comment.Content>
                  <Comment.Author as="a" style={{ color: "#fff" }}>
                    {toShortAddress(comment.wallet)}
                  </Comment.Author>
                  <Comment.Text style={{ color: "#fff" }}>
                    {comment.message}
                  </Comment.Text>
                </Comment.Content>
              </Comment>
            ))}
          </>
        ) : (
          <p className="text-center" style={{ color: "gray" }}>
            No comments yet. Be the first to comment on this token.{" "}
            <span role="img" aria-label="smile">
              😊
            </span>
          </p>
        )}
      </Comment.Group>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <label style={{ marginRight: "10px", cursor: "pointer" }}>
          <Icon
            name="archive"
            color="red"
            size="large"
            onClick={() => setOpen(true)}
          />
        </label>
        <Input
          value={message}
          onChange={handleMessageChange}
          placeholder="Type your message..."
          style={{ flex: 1, marginRight: "10px" }}
        />
        <Button icon color="blue" onClick={handleSend}>
          <Icon name="send" />
        </Button>
      </div>

      {/* Modal Component */}
      <Modal
        className="modal__style"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="tiny"
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header style={{ backgroundColor: "#f4f4f4", padding: "20px" }}>
          <h3 style={{ margin: 0 }}>Create a Packet</h3>
          <Modal.Actions
            style={{
              float: "right",
              marginTop: "-30px",
            }}
          >
            <Button color="black" type="button" onClick={() => setOpen(false)}>
              X
            </Button>
          </Modal.Actions>
        </Modal.Header>
        <Modal.Content style={{ padding: "20px" }}>
          <Form onSubmit={handlePackets}>
            <Form.Field>
              <label style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Amount
              </label>
              <input
                type="number"
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                style={{ padding: "10px", width: "100%", marginBottom: "15px" }}
                required
              />
            </Form.Field>
            <Form.Field>
              <label style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Max Claimants
              </label>
              <input
                type="number"
                placeholder="Max Claimants"
                value={maxClaimants}
                onChange={(e) => setMaxClaimants(e.target.value)}
                style={{ padding: "10px", width: "100%", marginBottom: "15px" }}
                required
              />
            </Form.Field>
            <Button
              type="submit"
              content="Create Packet"
              labelPosition="right"
              icon="checkmark"
              positive
              loading={loading}
            />
          </Form>
        </Modal.Content>
      </Modal>
    </div>
  );
}

export default Chat;

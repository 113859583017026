import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { GetPrice } from "./UpdateChart";

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ApexChart = ({ token, CA }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Fetch the token price data
    GetPrice(CA).then((res) => {
  
      // Check if `res` is an array, or if `res.data` is where the array is located
      const responseData = Array.isArray(res) ? res : res.data.data;
  
      if (Array.isArray(responseData)) {
        // Format the data for chart
        const labels = responseData.map((entry) => {
          const date = new Date(entry.time);
          return date.toLocaleTimeString(); // Format time as needed
        });
  
        const prices = responseData.map((entry) => parseFloat(entry.price).toFixed(10));
  
        // Prepare the chart data
        const data = {
          labels, // X-axis labels (formatted times)
          datasets: [
            {
              label: `${token.name} (${token.ticker})`,
              data: prices, // Y-axis data (prices)
              borderColor: "#00f", // Line color
              backgroundColor: "rgba(0, 0, 255, 0.3)", // Area color under the line
              fill: true,
              tension: 0.4,
            },
          ],
        };
  
        setChartData(data);
      } else {
        console.error("Data format error: Expected an array in response");
      }
    }).catch((error) => console.error("API Fetch Error:", error));
  }, [token, CA]);
  

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        title: {
          display: true,
          text: "Token Price (PEPE)",
        },
        ticks: {
          callback: function (value) {
            return Number(value).toFixed(5);
          },
        },
      },
    },
  };

  return (
    <div>
  {chartData ? (
    <Line  style={{
      height: '50vh', // Use a relative height
      maxHeight: '400px', // Set a max height for larger screens
      width: '100%', // Ensure full width
    }} data={chartData} options={options} />
  ) : (
    <p>Loading chart data...</p>
  )}
</div>

  );
};

export default ApexChart;

import React, { useState } from "react";
import { Form, Input } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { notify } from "../utils/notification";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toLongAddress } from "../utils/formatter";

const SearchBar = ({ curves, tokenDetails }) => {
  const [search, setSearch] = useState("");
  const [filteredTokens, setFilteredTokens] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const handleSearchInput = (e) => {
    const value = e.target.value;
    setSearch(value);

    if (!value) {
      setFilteredTokens([]);
      setShowDropdown(false);
      return;
    }

    const matchedTokens = curves.filter((curve) => {
      const tokenName = tokenDetails[curve]?.name?.toLowerCase();

      return (
        curve.toLowerCase().includes(value.toLowerCase()) ||
        (tokenName && tokenName.includes(value.toLowerCase()))
      );
    });

    setFilteredTokens(matchedTokens);
    setShowDropdown(matchedTokens.length > 0);
  };

  const handleTokenSelect = (curve) => {
    setSearch(tokenDetails[curve]?.name || curve);
    setShowDropdown(false);
    navigate(`/trade/${curve}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!search) {
      notify("Please enter a search term.");
      return;
    }

    const matchedCurve = curves.find(
      (curve) =>
        curve.toLowerCase() === search.toLowerCase() ||
        tokenDetails[curve]?.name.toLowerCase() === search.toLowerCase()
    );

    if (matchedCurve) {
      navigate(`/trade/${matchedCurve}`);
    } else {
      notify("No matching token found.");
    }
  };

  return (
    <div className="search__engine mt-5 text-center">
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Input
            type="text"
            placeholder="Search for tokens by address/name"
            value={search}
            onChange={handleSearchInput}
            onFocus={() => setShowDropdown(filteredTokens.length > 0)}
          />
        </Form.Field>

        {showDropdown && (
          <ul className="dropdown">
            {filteredTokens.length > 0 ? (
              <>
                {filteredTokens.map((curve) => {
                  const img = tokenDetails[curve]?.urls[0]; // Retrieve the token image URL
                  return (
                    <li
                      key={curve}
                      onClick={() => handleTokenSelect(curve)}
                      className="dropdown-item"
                    >
                      <div className="token-info">
                        <div>
                          {img && (
                            <LazyLoadImage
                              src={img}
                              alt={tokenDetails[curve]?.name}
                              className="token-img"
                            />
                          )}
                          <span>{tokenDetails[curve]?.name || curve}</span>
                        </div>
                        <small className="token-ticker">{toLongAddress(curve)}</small>
                      </div>
                    </li>
                  );
                })}
              </>
            ) : (
              <li className="dropdown-item not-found">
                No matching tokens found.
              </li>
            )}
          </ul>
        )}
      </Form>
    </div>
  );
};

export default SearchBar;

import React, { useEffect, useState } from "react";
import { BrowserProvider, Contract, formatUnits } from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { bondingCurverFactoryABI, StandardTokenABI } from "../config/abi";
import { bondingCurverFactory } from "../config/contracts";
import { Link } from "react-router-dom";

function Portfolio() {
  const { walletProvider } = useWeb3ModalProvider();
  const { address, isConnected } = useWeb3ModalAccount();
  const [tokens, setTokens] = useState([]);
  const [pageLoading, setPageLoading] = React.useState(true);

  useEffect(() => {
    async function fetchData() {
      const ethersProvider = new BrowserProvider(walletProvider);

      const signer = await ethersProvider.getSigner();

      try {
        const factoryContract = new Contract(
          bondingCurverFactory,
          bondingCurverFactoryABI,
          signer
        );
        const tokenAddresses = await factoryContract.getAllTokens();
        const nonZeroBalanceTokens = [];
        for (const tokenAddress of tokenAddresses) {
          const tokenContract = new Contract(
            tokenAddress,
            StandardTokenABI,
            ethersProvider
          );

          const balance = await tokenContract.balanceOf(address);
          const decimals = await tokenContract.decimals();
          const symbol = await tokenContract.symbol();
          const name = await tokenContract.name();

          const formattedBalance = parseFloat(formatUnits(balance, decimals));

          if (formattedBalance > 0) {
            nonZeroBalanceTokens.push({
              address: tokenAddress,
              symbol,
              balance: formattedBalance,
              name,
            });
          }
        }

        setTokens(nonZeroBalanceTokens);
        setPageLoading(false);
      } catch (error) {
        console.error(error);
        setPageLoading(false);
      }
    }

    if (isConnected) {
      fetchData();
    }
  }, [address, isConnected]);

  if (pageLoading) {
    return (
        <React.Fragment>
          <section className="py-5">
            <p className="text-center">
              {pageLoading ? <>Loading ...</> : <></>}
            </p>
          </section>
        </React.Fragment>
    );
  }
  if (!isConnected) {
    return <div>Connect Wallet</div>;
  }
  return (
    <div className="token-balances-container py-5 mt-5">
      <h4 className="token-balances-title">Token Balances</h4>
      <ul className="token-balances-list">
        {tokens.map((token) => (
          <Link to={`/trade/${token.address}`} className="token-balances-item" key={token.address}>
            <span className="token-symbol">{token.name} [{token.symbol}] </span>
            <span className="token-balance">{(token.balance).toFixed(2)}</span>
          </Link>
        ))}
      </ul>
    </div>
  );
}

export default Portfolio;

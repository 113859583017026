import axios from 'axios'

const BASE_URL = 'http://localhost:5050/app/v1'

const localServer = "http://127.0.0.1:5050/app/v1";
const testServer = "https://pumpevm-db.onrender.com/app/v1";
const productionServer = "https://pumpevm-db-pndg.onrender.com/app/v1";
const pepeDb = "https://pepe-db.onrender.com/app/v1"

export default axios.create({
  baseURL: pepeDb,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    "Accept": "application/json, text/plain, */*",
  },
});


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import {
  BrowserProvider,
  Contract,
  JsonRpcProvider,
  Wallet,
  formatUnits,
} from "ethers";
import SearchBar from "../components/SearchBar";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { bondingCurverFactoryABI, StandardTokenABI } from "../config/abi";
import { bondingCurverFactory } from "../config/contracts";
import { toLongAddress } from "../utils/formatter";
import preloader from "../assets/images/preloader.gif";
import { useEventContext } from "../context/EventContext";
import { getTrade } from "../components/UpdateChart";
import TradeLogs from "../components/TradeLogs";
import {formatMarketCap} from "../utils/formatter";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Home() {
  const { walletProvider } = useWeb3ModalProvider();
  const { setData } = useEventContext();


  const [curves, setCurves] = useState([]);
  const [tokenDetails, setTokenDetails] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [showTrade, setShowTrade] = useState(false);

  const FALLBACK_PRIVATE_KEY =
    "a340f57a5af0182ffa5e3abfeeadda409def53f0c62c8f47e3b7cba9d229ba1c";

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem("hasSeenPopup");
    if (!hasSeenPopup) {
      setShowPopup(true);
    }
  }, []);
  useEffect(() => {
    async function fetchData() {
      const ethersProvider = walletProvider
        ? new BrowserProvider(walletProvider)
        : new JsonRpcProvider(
            "https://rpc-pepe-unchained-gupg0lo9wf.t.conduit.xyz/"
          );
      const signer = walletProvider
        ? await ethersProvider.getSigner()
        : new Wallet(FALLBACK_PRIVATE_KEY, ethersProvider);

      try {
        const factoryContract = new Contract(
          bondingCurverFactory,
          bondingCurverFactoryABI,
          signer
        );

        const deployedAddresses = await factoryContract.getAllTokens();
        console.log(deployedAddresses);

        setCurves(deployedAddresses);

        const details = await Promise.all(
          deployedAddresses.map(async (contractAddress) => {
            const data = await factoryContract.getTokenDetailsByAddress(
              contractAddress
            );
            const curves = await factoryContract.bondingCurves(contractAddress);
            const TOKEN = new Contract(
              contractAddress,
              StandardTokenABI,
              signer
            );

            const [creator, blockNumber, description, url1, url2, url3, url4] =
              data;
            const name = await TOKEN.name();
            const symbol = await TOKEN.symbol();
            const launched = await factoryContract.tokenLaunched(contractAddress)

            const Tsupply = await factoryContract.tokenTotalSupply();
            const ethVirtualReserve = curves[4];  
      const RESERVE_REAL_TOKEN = curves[0];
      const realReserve = curves[2];

      const totalEth = formatUnits(realReserve + ethVirtualReserve)

      const price = Number((totalEth * 0.02) / formatUnits(RESERVE_REAL_TOKEN));

            return {
              name,
              symbol,
              address: contractAddress,
              owner: creator,
              blockNumber: blockNumber.toString(),
              description,
              urls: [url1, url2, url3, url4],
              price: price,
              supply: Tsupply,
              launched,
            };
          })
        );

        const detailsMap = {};
        details.forEach((item) => {
          detailsMap[item.address] = item;
        });
        setTokenDetails(detailsMap);
        const { data } = await getTrade();
        setData(data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, [walletProvider]);

  const handleAccept = () => {
    localStorage.setItem("hasSeenPopup", "true");
    setShowPopup(false);
  };

  const handleTradePg = () => {
    const btn = document.getElementById("tradebtn");
    if (showTrade) {
      setShowTrade(false);
      btn.style.background = "rgba(81, 252, 139, 0.79)";
      btn.textContent = "OpenTrades";
    } else {
      setShowTrade(true);

      btn.style.background = "red";
      btn.textContent = "CloseTrades";
    }
  };



  return (
    <React.Fragment>
      {showPopup && (
        <div style={popupOverlayStyle}>
          <div style={popupStyle}>
            <h2 style={{ color: "yellow" }}>How it works</h2>
            <p style={{ textAlign: "start" }}>
              The process is straightforward and inclusive, with features
              tailored to developers and traders alike:
            </p>

            <ul className="text-center pop__text">
              <li className="mt-2">
                <b>
                  1. For Token Creators
                  <i
                    class="fa-solid fa-helmet-safety"
                    style={{ color: "green", marginLeft: "3px" }}
                  ></i>
                  :
                </b>{" "}
                Use our platform to launch your meme token quickly and securely.
                Once launched, every buy and sell of your token automatically
                rewards you with 0.5% in the native blockchain asset, ensuring a
                sustainable incentive for continued engagement.
              </li>
              <li className="mt-3">
                <b>
                  {" "}
                  2. For Traders
                  <i
                    class="fa-solid fa-user"
                    style={{ color: "green", marginLeft: "3px" }}
                  ></i>
                  :
                </b>{" "}
                Explore an ever-expanding selection of meme tokens, each with
                its own unique community and vibe. With real-time trading and
                transparency, you can enjoy a fun, degen trading experience with
                direct access to a variety of tokens across multiple
                blockchains.
              </li>
              <li className="mt-3">
                <b>
                  3. For Community
                  <i
                    class="fa-solid fa-users"
                    style={{ color: "green", marginLeft: "3px" }}
                  ></i>
                  :
                </b>{" "}
                Random Claim Packets offer users a chance to receive free tokens
                simply by interacting on pumpevm.fun, making trading more
                rewarding and enjoyable.
              </li>
            </ul>
            <Button
              style={{
                background: "yellow",
                clior: "#000",
              }}
              onClick={handleAccept}
            >
              ACCEPT
            </Button>
          </div>
        </div>
      )}
      <section className="py-5">
        <div className="text-center">
          <a href="/create">
            <h3>[Create New Meme]</h3>
          </a>
        </div>

        <SearchBar curves={curves} tokenDetails={tokenDetails} />
      </section>

      <section className="py-3">
        <div>
          <h3>
            Meme Trading{" "}
            <Button
              style={{
                background: "rgba(81, 252, 139, 0.79)",
                color: "#fff",
                fontSize: "10px",
                padding: "5px 8px",
              }}
              id="tradebtn"
              onClick={handleTradePg}
            >
              OpenTrades
            </Button>
          </h3>
        </div>


        {showTrade ? (
          <div>
            <TradeLogs />
          </div>
        ) : (
          <></>
        )}

        <div className="meme__container mt-5">
          {curves.length > 0
            ? [...curves].reverse().map((contractAddress, key) => {
                const details = tokenDetails[contractAddress];
                return (
                  <div key={key}>
                    {details  ? (
                      <Link to={`/trade/${contractAddress}`}>
                        <div className="meme_items">
                          <div>
                            {details ? (
                              <LazyLoadImage src={details.urls[0]} alt="meme_png" />
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="meme__contents">
                            {details ? (
                              <>
                                <p>Created By {toLongAddress(details.owner)}</p>
                                <p>
                                  Name: {details.name} ({details.symbol})
                                </p>
                                <p style={{ color: "green" }}>
                                  {details.launched ? "BONDED" : <>
                                    Mcap:{" "}
                                    {formatMarketCap( parseFloat(formatUnits(details.supply)) * (details.price))}
                                  {" "}
                                  </>}
                                </p>
                                <p className="desc__p">
                                  Description: {details.description}
                                </p>
                              </>
                            ) : (
                              <p>Loading...</p>
                            )}
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <div style={{textAlign: "center", margin: "auto"}}>
                        <img effect="blur"
                     src={preloader} alt="meme" />
                      </div>

                    )}
                  </div>
                );
              })
            : "Loading..."}
        </div>
      </section>
    </React.Fragment>
  );
}

export default Home;

const popupOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const popupStyle = {
  backgroundColor: "rgba(81, 252, 139, 0.79)",
  color: "#333",
  padding: "20px",
  borderRadius: "8px",
  maxWidth: "400px",
  textAlign: "center",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
};

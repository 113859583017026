import React, { useEffect, useState } from "react";
import { Button, Container } from "semantic-ui-react";
import "../assets/css/styles.css";
import pump from "../assets/images/pump.png";
import pumpEv from "../assets/images/pumpevm.png";

function Layout({ children, title }) {
  useEffect(() => {
    document.title = title || "PumpEvm Fun";
  }, [title]);

  const [showPopup, setShowPopup] = useState(false);


  const handleAccept = () => {
    setShowPopup(false);
  };


  return (
    <React.Fragment>
      <nav>
        <div className="nav-wrapper">
          <ul className="nav-mobile">
            <a href="/" className="brand-logo">
              <img src={pump} alt="pump" className="img-fluid" />
            </a>

            <b style={{cursor: "pointer"}} onClick={() => setShowPopup(true)}>[How it works]</b>
          </ul>

          <a href="/portfolio" className="brand-logo">

          <b>[Portfolio]</b>
          
          </a>

          <div>
            <w3m-button />
          </div>
        </div>
      </nav>

      <Container>  {showPopup && (
        <div style={popupOverlayStyle}>
          <div style={popupStyle}>
            <h2 style={{ color: "yellow" }}>How it works</h2>
            <p style={{ textAlign: "start" }}>
              The process is straightforward and inclusive, with features
              tailored to developers and traders alike:
            </p>

            <ul className="text-center pop__text">
              <li className="mt-2">
                <b>
                  1. For Token Creators
                  <i
                    class="fa-solid fa-helmet-safety"
                    style={{ color: "green", marginLeft: "3px" }}
                  ></i>
                  :
                </b>{" "}
                Use our platform to launch your meme token quickly and securely.
                Once launched, every buy and sell of your token automatically
                rewards you with 0.5% in the native blockchain asset, ensuring a
                sustainable incentive for continued engagement.
              </li>
              <li className="mt-3">
                <b>
                  {" "}
                  2. For Traders
                  <i
                    class="fa-solid fa-user"
                    style={{ color: "green", marginLeft: "3px" }}
                  ></i>
                  :
                </b>{" "}
                Explore an ever-expanding selection of meme tokens, each with
                its own unique community and vibe. With real-time trading and
                transparency, you can enjoy a fun, degen trading experience with
                direct access to a variety of tokens across multiple
                blockchains.
              </li>
              <li className="mt-3">
                <b>
                  3. For Community
                  <i
                    class="fa-solid fa-users"
                    style={{ color: "green", marginLeft: "3px" }}
                  ></i>
                  :
                </b>{" "}
                Random Claim Packets offer users a chance to receive free tokens
                simply by interacting on pumpevm.fun, making trading more
                rewarding and enjoyable.
              </li>
            </ul>
            <Button
              style={{
                background: "yellow",
                clior: "#000",
              }}
              onClick={handleAccept}
            >
              ACCEPT
            </Button>
          </div>
        </div>
      )}{children}

      </Container>

      <footer className="mt-5">
        <div className="footer">
          <img src={pumpEv} alt="pump" className="img-fluid logo" />

          <div className="footer__left">
            <p>© PumpEvm 2024</p>
          </div>
          <div className="footer__right">
            <p>
              This site is protected by reCAPTCHA and the Google Privacy Policy
              and Terms of Service apply.
            </p>
          </div>

       <div className="social">
            <a href="https://t.me/pumpevm" target="_blank" rel="noreferrer">
              <i class="fa-brands fa-telegram"></i>
            </a>
            <a
              href="https://x.com/pumpevm_fun"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-twitter"></i>
            </a>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Layout;

const popupOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const popupStyle = {
  backgroundColor: "rgba(81, 252, 139, 0.79)",
  color: "#333",
  padding: "20px",
  borderRadius: "8px",
  maxWidth: "400px",
  textAlign: "center",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
};


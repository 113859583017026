import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../assets/css/board.css";
import ApexChart from "../components/ApexChart";
import Chat from "../components/Chat";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  formatEther,
  JsonRpcProvider,
  Wallet,
} from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { bondingCurverFactoryABI, StandardTokenABI } from "../config/abi";
import { bondingCurverFactory } from "../config/contracts";
import Trade from "../components/Trade";
import { useEventContext } from "../context/EventContext";
import { getTrade } from "../components/UpdateChart";
import EventListener from "../components/EventListener";
import Layout from "../components/Layout";
import { formatNumber, toShortAddress } from "../utils/formatter";
import { notify } from "../utils/notification";
import { Button } from "semantic-ui-react";
import TradeLogs from "../components/TradeLogs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TokenChart from "../components/TokenChart";
import LiveStream from "../components/LiveStream";

function Token() {
  const { walletProvider } = useWeb3ModalProvider();
  const { address, isConnected } = useWeb3ModalAccount();
  const { setData } = useEventContext();
  const [showTrade, setShowTrade] = useState(false);

  const FALLBACK_PRIVATE_KEY =
    "a340f57a5af0182ffa5e3abfeeadda409def53f0c62c8f47e3b7cba9d229ba1c";

  const { token } = useParams();
  const [pageLoading, setPageLoading] = React.useState(true);
  const [tokenData, setTokenData] = React.useState({
    name: "",
    ticker: "",
    description: "",
    tg: "",
    x: "",
    web: "",
    balance: 0,
    supply: 0,
    RESERVE_REAL_TOKEN: 0,
    price: 0,
    balanceInEther: 0,
    realReserve: 0,
    ethRealReserveThreshold: 10,
    img: "",
    contractBalance: 0,
    launched: false,
    owner: "",
  });


  
  useEffect(() => {
    update();
  }, [token, address, isConnected]);

  const handleCopy = () => {
    const urlToCopy = window.location.href;
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        notify("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleTradePg = () => {
    const btn = document.getElementById("tradebtn");
    if (showTrade) {
      setShowTrade(false);
      btn.style.background = "rgba(81, 252, 139, 0.79)";
      btn.textContent = "OpenTrades";
    } else {
      setShowTrade(true);

      btn.style.background = "red";
      btn.textContent = "CloseTrades";
    }
  };

  async function update() {
    try {
      const ethersProvider = walletProvider
        ? new BrowserProvider(walletProvider)
        : new JsonRpcProvider("https://rpc-pepe-unchained-gupg0lo9wf.t.conduit.xyz/");
      const signer = walletProvider
        ? await ethersProvider.getSigner()
        : new Wallet(FALLBACK_PRIVATE_KEY, ethersProvider);

      const balanceBigNumber = walletProvider
        ? await ethersProvider.getBalance(address)
        : 0;
      const balanceInEther = formatEther(balanceBigNumber);
      const { data } = await getTrade();
      setData(data);
      const contract = new Contract(
        bondingCurverFactory,
        bondingCurverFactoryABI,
        signer
      );
      const TOKEN = new Contract(token, StandardTokenABI, signer);

      const log = await contract.getTokenDetailsByAddress(token);
      const curves = await contract.bondingCurves(token);
      const Tsupply = await contract.tokenTotalSupply();
      const name = await TOKEN.name();
      const symbol = await TOKEN.symbol();
      const newBal = await TOKEN.balanceOf(bondingCurverFactory);
      const launched = await contract.tokenLaunched(token);

      const contractBalance = formatUnits(newBal) - 206900000;

      const [creator, blockNumber, description, url1, url2, url3, url4] = log;

      const ticker = symbol;
      const tg = url3;
      const x = url2;
      const web = url4;
      const supply = Tsupply;
      const realReserve = curves[2];
      const ethRealReserveThreshold = curves[3];
      const ethVirtualReserve = curves[4];
      const img = url1;
      const RESERVE_REAL_TOKEN = curves[0];
    

      const totalEth = formatUnits(realReserve + ethVirtualReserve);

      const price = Number((totalEth * 0.02) / formatUnits(RESERVE_REAL_TOKEN));

      let balance;
      if (isConnected) {
        balance = await TOKEN.balanceOf(address);
      } else {
        balance = 0;
      }

      setTokenData({
        name,
        ticker,
        description,
        tg,
        x,
        web,
        balance,
        supply,
        RESERVE_REAL_TOKEN,
        price,
        balanceInEther,
        realReserve,
        ethRealReserveThreshold,
        img,
        contractBalance,
        launched,
        owner: creator,
      });
      setPageLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  const percentage = Math.min(
    (formatEther(tokenData.realReserve) /
      formatEther(tokenData.ethRealReserveThreshold)) *
      100,
    100
  );

  if (pageLoading) {
    return (
      <Layout>
        <React.Fragment>
          <section className="py-5">
            <p className="text-center">
              {pageLoading ? <>Loading ...</> : <></>}
            </p>
          </section>
        </React.Fragment>
      </Layout>
    );
  }

  return (
    <Layout
      title={`Trading ${tokenData.name} for ${tokenData.price} on PumpEvm.fun`}
    >
      <EventListener />
      <React.Fragment>
        <div className="mt-5">
          <Link to="/">[Back]</Link>
        </div>

        {/* <LiveStream token={tokenData} ca={token} /> */}
        <section className="py-5">
          <div style={{ cursor: "pointer" }} className="copy__text mt-2 mb-3">
            <b onClick={handleCopy}>
              CA: {toShortAddress(token)} <i class="fa-solid fa-copy"></i>
            </b>
          </div>
          <div className="main__token__board">
            {tokenData.launched ? (
              <>
                <div className="alert alert-success">
                  <h3>Token Launched</h3>
                  <p>
                    The token has been launched. You can now trade on a
                    decentralized exchange (DEX).
                  </p>
                </div>
              </>
            ) : (
              <div>
                <ApexChart CA={token} token={tokenData} />
                {/* <TokenChart  CA={token} /> */}
              </div>
            )}

            <div>
              <Trade token={token} data={tokenData} updateFn={update} />
            </div>

            <div className="mt-5">
              <div>
                <Button
                  style={{
                    background: "rgba(81, 252, 139, 0.79)",
                    color: "#fff",
                    fontSize: "10px",
                    padding: "5px 8px",
                  }}
                  id="tradebtn"
                  onClick={handleTradePg}
                >
                  OpenTrades
                </Button>
              </div>

              {showTrade ? (
                <div>
                  <TradeLogs CA={token} />
                </div>
              ) : (
                <></>
              )}

              <Chat token={token} data={tokenData} updateFn={update} />
            </div>

            <div className="mt-5 token__info">
              {/* Bonding Curve Infor */}
              <div className="mt-5">
                <h3>Bonding Curve Information</h3>

                {tokenData.launched ? (
                  <div
                    style={{
                      background: "#e0e0e0",
                      borderRadius: "10px",
                      overflow: "hidden",
                      height: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: `100%`,
                        background: "green",
                        height: "100%",
                        transition: "width 0.5s ease-in-out",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p>100%</p>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      background: "#e0e0e0",
                      borderRadius: "10px",
                      overflow: "hidden",
                      height: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: `${percentage}%`,
                        background: percentage === 100 ? "#0f0" : "#00f",
                        height: "100%",
                        transition: "width 0.5s ease-in-out",
                        display: "flex", // Add flexbox
                        justifyContent: "center", // Center horizontally
                        alignItems: "center", // Center vertically
                      }}
                    >
                      <p style={{ color: "green" }}>{percentage.toFixed(2)}%</p>
                    </div>
                  </div>
                )}

                {tokenData.launched ? (
                  ""
                ) : (
                  <h4>
                    There are <b>{formatNumber(tokenData.contractBalance)}</b>{" "}
                    of {tokenData.ticker} tokens left to be sold with a total of{" "}
                    {formatNumber(formatUnits(tokenData.realReserve))} PEPE in
                    the reserve.
                  </h4>
                )}

                <p className="mt-2" style={{ color: "grey" }}>
                  Bonding Process: When the bonding curve reaches 100%, it
                  signifies the full allocation of tokens, triggering the
                  migration of both the token and its liquidity to a
                  decentralized exchange (DEX). At this stage, trading on the
                  current platform will cease, making the DEX the primary market
                  for token activity. This transition marks an exciting new
                  phase, opening up wider trading opportunities and enhancing
                  liquidity for token holders.
                </p>
              </div>

              <h2>Token Information</h2>

              <LazyLoadImage
                src={tokenData.img}
                alt="Logo"
                className="img-fluid token__logo"
              />

              <p className="mt-2">Description: {tokenData.description}</p>

              <p>
                TotalSupply:{" "}
                {parseFloat(formatUnits(tokenData.supply)).toFixed(2)}
              </p>

              <p>Socials</p>

              <div className="socials">
                <a href={tokenData.tg} target="_blank" rel="noreferrer">
                  <i class="fa-brands fa-telegram"></i>
                </a>
                <a href={tokenData.x} target="_blank" rel="noreferrer">
                  <i class="fa-brands fa-twitter"></i>
                </a>
                <a href={tokenData.web} target="_blank" rel="noreferrer">
                  <i class="fa-solid fa-circle-nodes"></i>
                </a>
              </div>

              {/* <Holders CA={token} /> */}
            </div>
          </div>
        </section>
      </React.Fragment>
    </Layout>
  );
}

export default Token;

import { notify } from "./notification";
import { StandardTokenABI } from "../config/abi";
import { Contract, parseEther } from "ethers";

export async function approveToken(
  amount,
  tokenAddress,
  contractAddress,
  signer,
  address
) {
  try {
    const TOKEN = new Contract(tokenAddress, StandardTokenABI, signer);
    const amountInWei = parseEther(amount.toString());
    const allowance = await TOKEN.allowance(address, contractAddress);
    if (allowance.toString() >= amountInWei) {
      return;
    }
    notify("Approve token in your wallet");
    const tx = await TOKEN.approve(contractAddress, amountInWei);
    await tx.wait();
  } catch (err) {
    console.error("Failed to approve token:", err);
  }
}

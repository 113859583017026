import Toastify from "toastify-js";

export const notify = (message) => {
  Toastify({
    text: `${message}`,
    duration: 8000,
    newWindow: true,
    close: true,
    gravity: "top",
    position: "center",
    stopOnFocus: true,
    style: {
      background: "rgba(44, 62, 80, 0.9)",
      borderRadius: "10px",
      fontSize: "12px",
      padding: "15px 20px",
      color: "#fff",
      textAlign: "center",
      fontWeight: "bolder",
      maxWidth: "500px",
      wordBreak: "break-word", 
      overflow: "hidden", 
      whiteSpace: "normal",
    },
    onClick: function () {},
  }).showToast();
};

import React, { useState } from "react";
import { formatNumber, toLongAddress } from "../utils/formatter";
import { useEventContext } from "../context/EventContext";
import { Link } from "react-router-dom";

const TradeLogs = ({ CA }) => {
    const { data } = useEventContext();

    // Filter trade logs based on CA if it's provided
    const tradeLogs = CA ? data.filter(log => log.contractAddress === CA) : data;

    console.log(tradeLogs);

    // State to track the number of trades to display
    const [visibleCount, setVisibleCount] = useState(20);

    // Function to load more trades
    const loadMoreTrades = () => {
        setVisibleCount(prevCount => prevCount + 20);
    };

    // Get the limited trade logs to display
    const limitedTradeLogs = tradeLogs.slice(0, visibleCount);

    return (
        <div className="mt-3">
            <h4>Trade Logs</h4>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={thStyle}>Address</th>
                        <th style={thStyle}>Type</th>
                        <th style={thStyle}>Amount</th>
                        <th style={thStyle}>Value</th>
                        <th style={thStyle}>Token Name</th>
                    </tr>
                </thead>
                <tbody>
                    {limitedTradeLogs.map((log, index) => (
                        <tr key={index}>
                            <td style={tdStyle}>
                                <Link to={`/trade/${log.contractAddress}`}>
                                    {toLongAddress(log.wallet)}
                                </Link>
                            </td>
                            <td style={tdStyle}>
                                {log.type === "buy" ? (
                                    <span style={{ color: "green" }}>{log.type}</span>
                                ) : (
                                    <span style={{ color: "red" }}>{log.type}</span>
                                )}
                            </td>
                            <td style={tdStyle}>{formatNumber(log.amount)}</td>
                            <td style={tdStyle}>{formatNumber(log.value)}</td>
                            <td style={tdStyle}>
                                <Link to={`/trade/${log.contractAddress}`}>{log.name}</Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {visibleCount < tradeLogs.length && (
                <button  onClick={loadMoreTrades} style={buttonStyle}>
                    Load More
                </button>
            )}
        </div>
    );
};

// Inline styles for the table and button
const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
    borderRadius: "5px",
    textAlign: "center",
};

const thStyle = {
    border: "1px solid #333",
    padding: "8px",
    backgroundColor: "grey",
};

const tdStyle = {
    border: "1px solid #333",
    padding: "8px",
    textAlign: "center",
};

const buttonStyle = {
    marginTop: "10px",
    padding: "5px 8px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "10px",
};

export default TradeLogs;

import React, { useState, useEffect } from "react";
import { Button, Form } from "semantic-ui-react";
import {
  BrowserProvider,
  Contract,
  parseEther,
  formatUnits,
  parseUnits,
  formatEther,
} from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { bondingCurverFactoryABI } from "../config/abi";
import { bondingCurverFactory } from "../config/contracts";
import { approveToken } from "../utils/blockFunction";
import { notify } from "../utils/notification";
import { UpdatePrice, GetPrice } from "./UpdateChart";
import { formatMarketCap, formatNumber } from "../utils/formatter";
import {Link} from "react-router-dom";

function Trade({ token, data, updateFn }) {
  const { isConnected } = useWeb3ModalAccount();
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [isBuy, setIsBuy] = useState(true);
  const [buyLoading, setBuyLoading] = useState(false);
  const [sellLoading, setsellLoading] = useState(false);
  const [amount, setAmount] = useState(null);

  const handleTradeType = (type) => {
    setIsBuy(type === "buy");
  };

  const [minTokenToReceive, setMinTokenToReceive] = useState(0);
  const [minEthToReceive, setMinEthToReceive] = useState(0);

  useEffect(() => {
    if (amount) {
      calculateTokenMinRec().then((result) => setMinTokenToReceive(result));
      calculateEthMinRec().then((res) => setMinEthToReceive(res));
    } else {
      setMinTokenToReceive(0);
      setMinEthToReceive(0);
    }
  }, [amount]);

  useEffect(() => {
    const interval = setInterval(async () => {
        await updateFn()
    }, 5000);

    return () => clearInterval(interval);
}, []);

  async function calculateTokenMinRec() {
    if (!amount) return 0;
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const bondingCurve = new Contract(
      bondingCurverFactory,
      bondingCurverFactoryABI,
      signer
    );

    const ethAmountIn = parseEther(amount);
    const estimatedTokenAmount = await bondingCurve.getTokenAmountOut(
      token,
      ethAmountIn
    );
    const formattedTokenAmount = formatUnits(estimatedTokenAmount, 18);
    return formattedTokenAmount;
  }

  async function calculateEthMinRec() {
    if (!amount) return 0;
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const bondingCurve = new Contract(
      bondingCurverFactory,
      bondingCurverFactoryABI,
      signer
    );

    try {
      const tokenAmountIn = parseUnits(amount);
      const estimatedEthAmount = await bondingCurve.getEthAmountOut(
        token,
        tokenAmountIn
      );
      const formattedEthAmount = formatUnits(estimatedEthAmount, 18);
      return formattedEthAmount;
    } catch (e) {
      console.log(e);
    }
  }

  async function handleTrade(e) {
    e.preventDefault();
    if (!isConnected) {
      notify("Connect wallet and try again");
      return;
    }
    if (!amount) {
      notify("Please enter a valid amount.");
      return;
    }

    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const bondingCurve = new Contract(
      bondingCurverFactory,
      bondingCurverFactoryABI,
      signer
    );

    if (isBuy) {
      if (amount > parseFloat(data.balanceInEther)) {
        notify("Insufficient balance");
        return;
      }
      setBuyLoading(true);

      try {
        
        const min = await bondingCurve.getTokenAmountOut(token, parseEther(amount));
        const formattedMin = parseUnits(formatUnits(min, 18));

        const tnx = await bondingCurve.buy(token, formattedMin, address, {
          value: parseEther(amount),
          gasLimit: 5000000,
        });
        await tnx.wait();
        notify("Transaction successful");
        setBuyLoading(false);
        setAmount("");

        await updateFn();
        const curves = await bondingCurve.bondingCurves(token);
        const value = formatUnits(formattedMin.toString());
        const metaData = {
          token: token,
          address: address,
          price: data.price,
          timeStamp: new Date().toISOString(),
          amount: value,
          value: amount,
          name: data.name,
          type: "buy",
        };
        await UpdatePrice(metaData);
        setTimeout(async () => {
          await GetPrice(token);
        }, 10000);
      } catch (error) {
        console.log(error);
        setBuyLoading(false);
        let errorMessage = "An unknown error occurred.";

        if (error && error.data && error.data.message) {
          errorMessage = error.data.message;
        } else if (error && error.message) {
          errorMessage = error.message;
        }

        console.error(errorMessage);
        errorMessage =
          errorMessage.length > 100
            ? `${errorMessage.slice(0, 150)}...`
            : errorMessage;
        notify(errorMessage);
      }
    } else {
      if (amount > parseFloat(formatUnits(data.balance))) {
        notify("Insufficient balance");
        return;
      }
      setsellLoading(true);
      try {
        await approveToken(
          amount,
          token,
          bondingCurverFactory,
          signer,
          address
        );

        const min = await calculateEthMinRec();
        const formattedMin = parseUnits(min);
        const tnx = await bondingCurve.sell(
          token,
          parseUnits(amount),
          formattedMin,
          address,
          {
            gasLimit: 5000000,
          }
        );
        await tnx.wait();
        notify("Transaction successful");
        setsellLoading(false);
        setAmount("");
        await updateFn();
        const curves = await bondingCurve.bondingCurves(token);
        const metaData = {
          token: token,
          address: address,
          price: data.price,
          timeStamp: new Date().toISOString(),
          name: data.name,
          type: "sell",
          amount: Number(min),
          value: amount,
        };
        await UpdatePrice(metaData);
        setTimeout(async () => {
          await GetPrice(token);
        }, 10000);
      } catch (error) {
        console.log(error);
        setsellLoading(false);
        let errorMessage = "An unknown error occurred.";

        if (error && error.data && error.data.message) {
          errorMessage = error.data.message;
        } else if (error && error.message) {
          errorMessage = error.message;
        }

        console.error(errorMessage);
        errorMessage =
          errorMessage.length > 100
            ? `${errorMessage.slice(0, 150)}...`
            : errorMessage;
        notify(errorMessage);
      }
    }
  }

  function handleButton(e) {
    setAmount(e);
  }

  function handleTradePercent(e) {
    if (parseFloat(formatUnits(data.balance)).toFixed(2) < 0) {
      setAmount(0);
    }
    if (e === "10") {
      setAmount(String(parseFloat(formatUnits(data.balance)).toFixed(2) * 0.1));
    } else if (e === "25") {
      setAmount(
        String(parseFloat(formatUnits(data.balance)).toFixed(2) * 0.25)
      );
    } else if (e === "75") {
      setAmount(
        String(parseFloat(formatUnits(data.balance)).toFixed(2) * 0.75)
      );
    } else {
      setAmount(String(parseFloat(formatUnits(data.balance)).toFixed(2) - 0.5));
    }
  }
  return (
    <div>
      
      {data.launched ? "" : <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <p>Price: {(data.price)}</p>
        <p style={{ color: "green", fontWeight: "900" }}>
          Mcap:{" "}
          {formatMarketCap( parseFloat(formatUnits(data.supply)) * (data.price))}{" "}
        </p>
      </div>}

      <div className="trade__btn">
        <Button
          fluid
          onClick={() => handleTradeType("buy")}
          color={isBuy ? "green" : "grey"}
        >
          Buy
        </Button>
        <Button
          fluid
          onClick={() => handleTradeType("sell")}
          color={!isBuy ? "red" : "grey"}
        >
          Sell
        </Button>
      </div>

      {data.launched ? (
        <>
        <Link to="https://pepedex.pointswap.fun">
        <Button fluid primary className="mt-5">
            Trade Now On Dex
          </Button>
        </Link>
        
        </>
      ) : (
        <Form className="mt-5" onSubmit={handleTrade}>
          <Form.Field>
            <p>
              {isBuy ? (
                <>
                  Buy Amount
                  <p className="mt-2" style={{ float: "right" }}>
                    Balance:{" "}
                    {data.balanceInEther
                      ? parseFloat(data.balanceInEther).toFixed(2)
                      : 0}
                  </p>
                </>
              ) : (
                <>
                  Sell Amount
                  <p className="mt-2" style={{ float: "right" }}>
                    Balance:{" "}
                    {data.balance
                      ? parseFloat(formatUnits(data.balance)).toFixed(2)
                      : 0}
                  </p>
                </>
              )}
            </p>
            <input
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
              type="number"
              step="any"
              placeholder={isBuy ? "Amount to buy" : "Amount to sell"}
              required
            />
          </Form.Field>

          {isBuy ? (
            <div>
              <div className="mini__btn">
                <span onClick={() => handleButton("0.1")}>0.1 PEPE</span>
                <span onClick={() => handleButton("0.5")}>0.5 PEPE</span>
                <span onClick={() => handleButton("1")}>1 PEPE</span>
                <span onClick={() => handleButton("5")}>5 PEPE</span>
              </div>
              <p className="mt-2" style={{ color: "gray" }}>
                {amount && !minTokenToReceive
                  ? "Looking for best price..."
                  : `Minimum ${data.name} token to receive: ${formatNumber(
                      minTokenToReceive
                    )}`}
              </p>
            </div>
          ) : (
            <div>
              <div className="mini__btn">
                <span onClick={() => handleTradePercent("10")}>10%</span>
                <span onClick={() => handleTradePercent("25")}>25%</span>
                <span onClick={() => handleTradePercent("75")}>75%</span>
                <span onClick={() => handleTradePercent("100")}>100%</span>
              </div>

              <p className="mt-2" style={{ color: "gray" }}>
                {amount && !minEthToReceive
                  ? "Looking for best price..."
                  : `Minimum PEPE token to receive: ${formatNumber(
                      minEthToReceive
                    )}`}
              </p>
            </div>
          )}

          <div className="mt-5">
            {isConnected ? (
              <>
                {" "}
                {isBuy ? (
                  <Button
                    type="submit"
                    loading={buyLoading}
                    style={{
                      background: "rgba(81, 252, 139, 0.79)",
                      color: "#fff",
                    }}
                    fluid
                  >
                    Buy
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    style={{
                      background: "rgba(81, 252, 139, 0.79)",
                      color: "#fff",
                    }}
                    loading={sellLoading}
                    fluid
                  >
                    Sell
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button
                  style={{
                    background: "rgba(81, 252, 139, 0.79)",
                    color: "#fff",
                  }}
                  disabled
                  loading={sellLoading}
                  fluid
                >
                  Please Connect Wallet
                </Button>
              </>
            )}
          </div>
        </Form>
      )}
    </div>
  );
}

export default Trade;

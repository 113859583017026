import axios from "../api/axios";

export async function UpdatePrice(data) {
  try {
    const newData = {
      contractAddress: data.token,
      wallet: data.address,
      price: data.price,
      time: data.timeStamp,
      name: data.name,
      type: data.type,
      amount: data.amount,
      value: data.value,
    };
    const res = axios.post("/price", newData);
    console.log(res);
  } catch (error) {
    console.log(error);
  }
}

export async function GetPrice(contract) {
  try {
    const res = axios.get(`/getprice/${contract}`);
    return res;
  } catch (error) {
    console.log(error);
  }
}
export async function getTrade() {
  try {
    const res = axios.get(`/trade`);
    return res;
  } catch (error) {
    console.log(error);
  }
}
